






import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({})
export default class CommandCreateComponent extends Vue {
  @Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;

  @Prop({ type: String })
  to;
}
