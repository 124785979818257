const clientId = process.env.VUE_APP_CLIENT_ID;
const authority = process.env.VUE_APP_AUTHORITY;
const redirectUri = process.env.VUE_APP_URL_REDIRECT;
const scope_corporative_api = process.env.VUE_APP_SCOPE_CORPORATIVE_API;
const scope_security_api = process.env.VUE_APP_SCOPE_SECURITY_API;
const redirectLogoutUri = process.env.VUE_APP_URL_REDIRECT_LOGOUT;

import _Vue from 'vue';
import * as Msal from '@azure/msal-browser';
import User from '../types/user';

export function Auth(Vue: typeof _Vue): void {
  const msalConfig = {
    auth: {
      clientId: clientId,
      authority: authority,
      redirectUri: redirectUri
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    }
  };

  const loginRequest = {
    scopes: ['openid', 'profile']
  };

  const tokenRequest: any = {
    scopes: [scope_corporative_api],
    forceRefresh: false
  };

  const tokenSecurityRequest: any = {
    scopes: [scope_security_api],
    forceRefresh: true
  };

  const graphRequest: any = {
    scopes: ['User.Read']
  };

  const msalInstance = new Msal.PublicClientApplication(msalConfig);

  Vue.prototype.$getUserAsync = async () => {
    try {
      const currentAccounts = msalInstance.getAllAccounts();

      if (currentAccounts.length === 0) {
        return null;
      }

      if (currentAccounts.length === 1) {
        const data = msalInstance.getAccountByUsername(currentAccounts[0].username);

        return {
          name: data?.name,
          email: data?.username
        } as User;
      }

      return null;
    } catch (error) {
      console.log('authService: getTokenData()', error);

      return null;
    }
  };

  Vue.prototype.$loginAsync = async () => {
    try {
      const response = await msalInstance.loginPopup(loginRequest);

      return response && response.accessToken;
    } catch (error) {
      console.log('authService I: loginAsync()', error);
      return false;
    }
  };

  Vue.prototype.$logoutAsync = async () => {
    console.log('logoutAsync');
  };

  Vue.prototype.$getTokenAsync = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        return null;
      }

      if (accounts.length > 1) {
        return null;
      }

      tokenRequest.account = accounts[0];

      const response = await msalInstance.acquireTokenSilent(tokenRequest);

      return response.accessToken;
    } catch (error) {
      try {
        console.log('authService II: getTokenAsync()', error);

        const resp = await msalInstance.acquireTokenPopup(tokenRequest);

        return resp.accessToken;
      } catch (error) {
        return null;
      }
    }
  };

  Vue.prototype.$getUserAsync = async () => {
    try {
      const currentAccounts = msalInstance.getAllAccounts();
      if (currentAccounts.length === 0) {
        return null;
      }

      if (currentAccounts.length === 1) {
        const data = msalInstance.getAccountByUsername(currentAccounts[0].username);

        return {
          name: data?.name,
          email: data?.username
        } as User;
      }

      return null;
    } catch (error) {
      console.log('authService: getTokenData()', error);

      return null;
    }
  };

  Vue.prototype.$getTokenForMenuAsync = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        return null;
      }

      if (accounts.length > 1) {
        return null;
      }

      tokenSecurityRequest.account = accounts[0];

      const response = await msalInstance.acquireTokenSilent(tokenSecurityRequest);

      return response.accessToken;
    } catch (error) {
      console.log('authService: getTokenForMenuAsync()', error);

      const response = await msalInstance.acquireTokenPopup(tokenSecurityRequest);

      return response.accessToken;
    }
  };

  Vue.prototype.$getTokenGraphAsync = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();

      if (!accounts || accounts.length === 0 || accounts.length > 1) {
        return null;
      }

      graphRequest.account = accounts[0];
      const response = await msalInstance.acquireTokenSilent(graphRequest);

      return response.accessToken;
    } catch (error) {
      console.log('authService I: getTokenGraph()', error);
      if (error.errorCode === 'invalid_grant') {
        try {
          const accounts = msalInstance.getAllAccounts();

          graphRequest.account = accounts[0];
          const response = await msalInstance.acquireTokenPopup(graphRequest);

          return response.accessToken;
        } catch (error) {
          console.log('authService II: getTokenGraph()', error);
          return null;
        }
      }
      console.log('authService: getTokenGraph(): ', JSON.parse(JSON.stringify(error)));
      return null;
    }
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $loginAsync(): Promise<boolean>;
    $logoutAsync(): Promise<string | null>;
    $getTokenAsync(): Promise<string | null>;
    $getTokenForMenuAsync(): Promise<string | null>;
    $getTokenGraphAsync(): Promise<string | null>;
    $getUserAsync(): Promise<User | null>;
  }
}
