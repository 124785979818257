import _Vue from 'vue';

function format(k, v) {
  return v !== null ? `${k}=${encodeURIComponent(v)}` : '';
}

function toQs(obj: any) {
  const concat = Object.entries(obj).map(([k, v]) =>
    Array.isArray(v) ? v.map(arr => toQs({ [k]: arr })) : format(k, v)
  );

  const retVal = []
    .concat(...concat)
    .filter(x => x)
    .join('&');

  return retVal;
}

export function Utils(Vue: typeof _Vue): void {
  Vue.prototype.$to_qs = (obj: any) => {
    return toQs(obj);
  };

  Vue.prototype.$formatCurrency = (value: number | null, isValueActive: boolean) => {
    return !value || !isValueActive
      ? ''
      : value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
  };

  Vue.prototype.$formatNumber = (value: number | null, isValueActive: boolean) => {
    if (!value || !isValueActive) return '';

    return value.toLocaleString('en-US');
    // const splited = value.toFixed(2).split('.');
    // return splited[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (splited[1] ? '.' + splited[1] : '');
  };

  Vue.prototype.$onlyNumber = (evt: any) => {
    const numbers = '0123456789';
    if (numbers.indexOf(evt.key) === -1) {
      evt.preventDefault();
    }
  };

  Vue.prototype.$onlyNumberDot = (evt: any) => {
    const numbers = '0123456789.';
    if (numbers.indexOf(evt.key) === -1) {
      evt.preventDefault();
    }
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $to_qs(obj: any): string;
    $formatCurrency(value: number | null, isValueActive: boolean): string;
    $formatNumber(value: number | null, isValueActive: boolean): string;
    $onlyNumber(evt: any): void;
    $onlyNumberDot(evt: any): void;
  }
}
