import Header from './header';

export default class FundHeader {
  public static header: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Fund',
      align: 'start',
      sortable: true,
      value: 'fund',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Adm. Tax',
      align: 'start',
      sortable: true,
      value: 'administrationTax',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Edit',
      align: 'right',
      value: 'actions',
      sortable: false,
      class: null,
      width: '5%'
    }
  ];
}
