


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class GenericDatesComponent extends Vue {
  @Prop({ type: Object })
  model;

  @Prop({ type: Array })
  rules;

  @Prop({ type: String })
  label;

  menu = false;

  get date(): string | null {
    return this.model.date ? this.formatDate(new Date(this.model.date).toISOString().substr(0, 10)) : null;
  }
  set date(value: string | null) {
    this.model.date = this.parseDate(value);
  }

  formatDate(date): any {
    if (!date) return null;

    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }

  parseDate(date): any {
    if (!date) return null;

    const [month, day, year] = date.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
}
