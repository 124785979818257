








































































import { Component, Vue, Watch } from 'vue-property-decorator';
import qs from 'qs';
import Api from '../../utils/api';

import Header from '../../types/app/header';
import OutflowHeader from '../../types/app/outflowHeader';

import CommandCreate from '../../auth/views/CommandCreate.vue';
import CommandEdit from '../../auth/views/CommandEdit.vue';
import Outflow from '@/src/types/outflow/outflow';

@Component({
  components: {
    CommandCreate: CommandCreate,
    CommandEdit: CommandEdit
  }
})
export default class IndexRescue extends Vue {
  headers: Array<Header> = OutflowHeader.header;
  loading = false;

  disableFunds = false;
  loadingFunds = true;

  loadingPeople = true;
  outflows: Array<any> = [];
  funds: Array<any> = [];
  people: Array<any> = [];

  filter = {
    skip: 0,
    take: 10,
    search: '',
    personIds: [],
    fundIds: [],
    officerIds: []
  };

  async created() {
    await this.getFundsAsync();
    await this.getPeopleAsync();
    await this.getOutflows();
  }

  async getByFilterAsync(): Promise<void> {
    this.outflows = [];
    this.loading = true;

    this.disableOptions(true);
    await this.getOutflows();
    this.disableOptions(false);
  }

  disableOptions(disable: boolean) {
    this.funds.forEach(fund => {
      fund.disabled = disable;
    });

    this.people.forEach(person => {
      person.disabled = disable;
    });
  }

  async getFundsAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(
        `${Api.Url.fund.getFunds}?${this.$to_qs({
          skip: 0,
          take: 1000
        })}`
      );

      if (resp.data) {
        this.funds = resp.data.data.data.map(x => {
          return {
            text: x.name,
            value: x.id,
            disabled: false
          };
        });
      }
      this.loadingFunds = false;
    } catch (error) {
      this.loadingFunds = false;
      console.log('error', error);
    }
  }

  async getPeopleAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(
        `${Api.Url.person.getPeople}?${this.$to_qs({
          skip: 0,
          take: 1000
        })}`
      );
      if (resp.data) {
        this.people = resp.data.data.data.map(x => {
          return {
            text: x.name,
            value: x.id,
            disabled: false
          };
        });
      }
    } catch (error) {
      this.loadingPeople = false;
      console.log('error', error);
    }
  }

  async getOutflows(): Promise<void> {
    try {
      const resp = await this.$http.get(`${Api.Url.outflow.getOutflows}?${qs.stringify(this.filter)}`);

      if (resp.status === 200 && resp.data.data) {
        resp.data.data.data.forEach((inflow: Outflow) => {
          this.outflows.push({
            id: inflow.id,
            fund: inflow.fund?.name,
            client: inflow.person?.name,
            account: inflow.account,
            financial: this.$formatCurrency(inflow.financial, true)
          });
        });
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log('error_inflow', error);
    }
  }

  goToDetail(item: any): void {
    this.$router.push({ name: 'FormOutflow', params: { id: item.id } });
  }
}
