




import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({})
export default class CommandCreateComponent extends Vue {
  @Getter('canRead', { namespace: 'auth' }) canRead!: boolean;

  @Prop({ type: Function })
  goToDetail;

  @Prop({ type: Object })
  item;
}
