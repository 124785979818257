import Header from './header';

export default class InflowHeader {
  public static header: Header[] = [
    {
      text: 'Fund',
      align: 'start',
      sortable: true,
      value: 'fund',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Client',
      align: 'start',
      sortable: true,
      value: 'client',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Account',
      align: 'start',
      sortable: true,
      value: 'account',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Financial',
      align: 'start',
      sortable: true,
      value: 'financial',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Edit',
      align: 'right',
      value: 'actions',
      sortable: false,
      class: null,
      width: '5%'
    }
  ];
}
