















































import { Component, Vue, Watch } from 'vue-property-decorator';

import { debounce } from 'lodash';
import Api from '../../utils/api';

import Header from '../../types/app/header';
import OfficerHeader from '../../types/app/officerHeader';
import Officer from '../../types/Officer/officer';

import CommandCreate from '../../auth/views/CommandCreate.vue';
import CommandEdit from '../../auth/views/CommandEdit.vue';

@Component({
  components: {
    CommandCreate: CommandCreate,
    CommandEdit: CommandEdit
  }
})
export default class IndexRescue extends Vue {
  headers: Array<Header> = OfficerHeader.header;
  loading = false;
  officers: Array<Officer> = [];
  debounceSearch: any = null;

  filter = {
    skip: 0,
    take: 10,
    search: ''
  };

  @Watch('filter.search')
  private async filtering() {
    if ((this.filter.search && this.filter.search.length >= 3) || this.filter.search.length == 0) {
      this.loading = true;
      this.officers = [];
      this.debounceSearch();
    }
  }

  async created() {
    this.debounceSearch = debounce(this.getOfficers, 1000);
    await this.getOfficers();
  }

  async getOfficers(): Promise<void> {
    try {
      this.loading = true;
      const resp = await this.$http.get(`${Api.Url.officer.getOfficers}?${this.$to_qs(this.filter)}`);
      if (resp.data) {
        resp.data.data.data.forEach((officer: Officer) => {
          this.officers.push({
            id: officer.id,
            name: officer.name,
            shortName: officer.shortName,
            active: officer.active ? 'Sim' : 'Não'
          });
        });
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log('error', error);
    }
  }

  goToDetail(item: any): void {
    this.$router.push({ name: 'FormOfficer', params: { id: item.id } });
  }
}
