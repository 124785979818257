






























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class Login extends Vue {
  async created() {
    await this.$logoutAsync();
  }
}
