















































import { Component, Vue, Watch } from 'vue-property-decorator';

import { debounce } from 'lodash';
import Api from '../../utils/api';

import Header from '../../types/app/header';
import FundHeader from '../../types/app/fundHeader';
import Fund from '../../types/fund/fund';

import CommandCreate from '../../auth/views/CommandCreate.vue';
import CommandEdit from '../../auth/views/CommandEdit.vue';

@Component({
  components: {
    CommandCreate: CommandCreate,
    CommandEdit: CommandEdit
  }
})
export default class IndexVehicle extends Vue {
  headers: Array<Header> = FundHeader.header;
  vehicles: Array<any> = [];
  funds: Array<any> = [];
  clients: Array<any> = [];
  loading = false;

  debounceSearch: any = null;
  filter = {
    skip: 0,
    take: 10,
    search: ''
  };

  @Watch('filter.search')
  private async filtering() {
    if ((this.filter.search && this.filter.search.length >= 3) || this.filter.search.length == 0) {
      this.loading = true;
      this.funds = [];
      this.debounceSearch();
    }
  }

  async created() {
    this.debounceSearch = debounce(this.getFundsAsync, 1000);
    await this.getVehiclesAsync();
    await this.getFundsAsync();
  }

  get fundMerged(): any[] {
    const retVal = [] as any[];

    if (this.funds?.length > 0 && this.vehicles?.length > 0) {
      this.funds.forEach((fund: Fund) => {
        this.vehicles.forEach(vehicle => {
          if (fund.externalFundId === vehicle.value) {
            retVal.push({
              id: fund.id,
              externalFundId: fund.externalFundId,
              name: fund.name,
              fund: vehicle.text,
              administrationTax: fund.administrationTax
            });
          }
        });
      });
    }
    this.loading = false;
    return retVal;
  }

  async getVehiclesAsync(): Promise<void> {
    const resp = await this.$http.get(`${Api.Url.fund.getVehicles}?
      ${this.$to_qs({
        skip: 0,
        take: 1000,
        entityType: 'vehicle'
      })}`);

    if (resp.status === 200 && resp.data.data) {
      this.vehicles = resp.data.data.map(x => {
        return {
          text: x.shortName,
          value: x.id
        };
      });
    }
  }

  async getFundsAsync(): Promise<void> {
    try {
      this.loading = true;
      console.log('this.filter', this.filter);

      const resp = await this.$http.get(`${Api.Url.fund.getFunds}?${this.$to_qs(this.filter)}`);
      if (resp.data) {
        resp.data.data.data.forEach((fund: Fund) => {
          this.funds.push({
            id: fund.id,
            externalFundId: fund.externalFundId,
            name: fund.name,
            administrationTax: this.$formatCurrency(fund.administrationTax, true)
          });
        });
      }
    } catch (error) {
      this.loading = false;
      console.log('error', error);
    }
  }

  goToDetail(item: any): void {
    this.$router.push({ name: 'FormFund', params: { id: item.id } });
  }
}
