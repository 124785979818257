















































import { Component, Vue, Watch } from 'vue-property-decorator';

import { debounce } from 'lodash';
import Api from '../../utils/api';

import Header from '../../types/app/header';
import PeopleHeader from '../../types/app/peopleHeader';
import Person from '../../types/people/person';

import CommandCreate from '../../auth/views/CommandCreate.vue';
import CommandEdit from '../../auth/views/CommandEdit.vue';

@Component({
  components: {
    CommandCreate: CommandCreate,
    CommandEdit: CommandEdit
  }
})
export default class IndexClient extends Vue {
  loading = false;
  headers: Array<Header> = PeopleHeader.header;
  people: Array<any> = [];
  clients: Array<any> = [];
  debounceSearch: any = null;

  filter = {
    skip: 0,
    take: 10,
    search: ''
  };

  @Watch('filter.search')
  private async filtering() {
    if ((this.filter.search && this.filter.search.length >= 3) || this.filter.search.length == 0) {
      this.loading = true;
      this.people = [];
      this.debounceSearch();
    }
  }

  async created() {
    this.debounceSearch = debounce(this.getPeopleAsync, 1000);
    await this.getPeopleAsync();
    await this.getClientsAsync();
  }

  get peopleMerged(): Person[] {
    const retVal = [] as Person[];

    if (this.people?.length > 0 && this.clients?.length > 0) {
      this.people.forEach((person: Person) => {
        this.clients.forEach(client => {
          if (person.externalId === client.id) {
            retVal.push({
              id: person.id,
              externalId: person.externalId,
              name: person.name,
              email: client.email,
              personType: person.personType,
              externalType: person.externalType,
              active: person.active ? 'Sim' : 'Não'
            });
          }
        });
      });
    }

    this.loading = false;
    return retVal;
  }

  async getClientsAsync(): Promise<void> {
    const resp = await this.$http.get(
      `${Api.Url.person.getClients}?${this.$to_qs({
        skip: 0,
        take: 1000
      })}`
    );

    if (resp.data.statusCode.item1 === 200) {
      this.clients = resp.data.data.data;
    }
  }

  async getPeopleAsync(): Promise<void> {
    try {
      this.loading = true;
      const resp = await this.$http.get(`${Api.Url.person.getPeople}?${this.$to_qs(this.filter)}`);
      if (resp.data) {
        resp.data.data.data.forEach((person: Person) => {
          this.people.push({
            id: person.id,
            externalId: person.externalId,
            name: person.name,
            personType: person.personType,
            externalType: person.externalType,
            active: person.active ? 'Sim' : 'Não'
          });
        });
      }
    } catch (error) {
      this.loading = false;
      console.log('error', error);
    }
  }

  goToDetail(item: any): void {
    this.$router.push({ name: 'FormPerson', params: { id: item.id } });
  }
}
