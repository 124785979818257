






































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import Api from '../../utils/api';

import Inflow from '../../types/inflow/inflow';
import Decimal from '../../components/generic/Decimal.vue';
import Integer from '../../components/generic/Integer.vue';
import Date from '../../components/generic/Date.vue';

import CommandsComponent from '../../auth/views/Commands.vue';

@Component({
  components: {
    Decimal: Decimal,
    Integer: Integer,
    Date: Date,
    CommandsComponent: CommandsComponent
  }
})
export default class OutflowForm extends Vue {
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  isCreate = true;
  valid = null;
  saving = false;
  loading = false;
  loadingFunds = true;
  loadingPeople = true;
  funds: Array<any> = [];
  people: Array<any> = [];
  officers: Array<any> = [];
  distributors: Array<any> = [];
  rules = [(v: any) => !!v || 'This field is required'];

  quotaQuantity = {
    value: 0
  };

  quotaValueDate = {
    date: ''
  };

  quotaValue = {
    value: 0
  };

  returnOfficerValue = {
    value: 0
  };

  returnDistribuitorValue = {
    value: 0
  };

  financial = {
    value: 0
  };

  financialDate = {
    date: ''
  };

  inflow = {} as Inflow;

  async created() {
    this.isCreate = !this.$route.params.id;
    await this.getFundsAsync();
    await this.getPeopleAsync();
    await this.getOfficersAsync();

    if (!this.isCreate) {
      await this.getInflowAsync();
    }
  }

  async getFundsAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(
        `${Api.Url.fund.getFunds}?${this.$to_qs({
          skip: 0,
          take: 1000
        })}`
      );

      if (resp.data) {
        this.funds = resp.data.data.data.map(x => {
          return {
            text: x.name,
            value: x.id
          };
        });
      }
      this.loadingFunds = false;
    } catch (error) {
      this.loadingFunds = false;
      console.log('error', error);
    }
  }

  async getPeopleAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(
        `${Api.Url.person.getPeople}?${this.$to_qs({
          skip: 0,
          take: 1000
        })}`
      );
      if (resp.data) {
        this.people = resp.data.data.data.map(x => {
          return {
            text: x.name,
            value: x.id
          };
        });
      }
    } catch (error) {
      this.loadingPeople = false;
      console.log('error', error);
    }
  }

  async getOfficersAsync(): Promise<void> {
    try {
      this.loading = true;
      const resp = await this.$http.get(
        `${Api.Url.officer.getOfficers}?${this.$to_qs({
          skip: 0,
          take: 1000
        })}`
      );
      if (resp.data) {
        this.officers = resp.data.data.data.map(x => {
          return {
            text: x.name,
            value: x.id
          };
        });
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log('error', error);
    }
  }

  async getInflowAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(`${Api.Url.inflow.get}/${this.$route.params.id}`);

      if (resp.status === 200 && resp.data.data) {
        this.inflow = resp.data.data;
        this.quotaQuantity.value = this.inflow.quotaQuantity ?? 0;
        this.quotaValueDate.date = this.inflow.quotaValueDate ?? '';
        this.quotaValue.value = this.inflow.quotaValue ?? 0;
        this.returnOfficerValue.value = this.inflow.returnOfficerValue ?? 0;
        this.returnDistribuitorValue.value = this.inflow.returnDistribuitorValue ?? 0;
        this.financial.value = this.inflow.financial ?? 0;
        this.financialDate.date = this.inflow.financialDate ?? '';
      }
    } catch (error) {
      console.log('individuos', error);
    }
  }

  async saveAsync(): Promise<void> {
    console.log(JSON.parse(JSON.stringify(this.inflow)));
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.inflow.quotaQuantity = this.quotaQuantity.value;
      this.inflow.quotaValueDate = this.quotaValueDate.date;
      this.inflow.quotaValue = this.quotaValue.value;
      this.inflow.returnOfficerValue = this.returnOfficerValue.value;
      this.inflow.returnDistribuitorValue = this.returnDistribuitorValue.value;
      this.inflow.financial = this.financial.value;
      this.inflow.financialDate = this.financialDate.date;

      this.saving = true;
      if (this.isCreate) {
        await this.createAsync();
      } else {
        await this.updateAsync();
      }
    }
  }

  async createAsync(): Promise<void> {
    try {
      const resp = await this.$http.post(Api.Url.inflow.create, {
        inflow: this.inflow
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message = resp.data.statusCode.item1 === 200 ? 'Inflow saved with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
      this.$router.push({ name: 'IndexInflow' });
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in create Inflow',
        color: 'error'
      });
    }
  }

  async updateAsync(): Promise<void> {
    try {
      const resp = await this.$http.put(Api.Url.inflow.update, {
        id: this.$route.params.id,
        inflow: this.inflow
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message = resp.data.statusCode.item1 === 200 ? 'Inflow updated with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
      this.$router.push({ name: 'IndexInflow' });
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in update Inflow',
        color: 'error'
      });
    }
  }
}
