import { ActionContext } from 'vuex';
import { UserState } from './types';

import User from '../../types/User';
import Area from '../../types/area';
import CurrentRoute from '../../types/currentRoute';

export default {
  setUser(store: ActionContext<UserState, any>, user: User): void {
    store.commit('setUser', user);
  },
  setAreas(store: ActionContext<UserState, any>, areas: Area[]): void {
    store.commit('setAreas', areas);
  },
  setCurrentRoute(store: ActionContext<UserState, any>, currentRoute: CurrentRoute): void {
    store.commit('setCurrentRoute', currentRoute);
  },

  disconnectToken(store: ActionContext<UserState, any>): void {
    store.commit('setUser', null);
  }
};
