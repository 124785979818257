




















































import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class GenericDatesComponent extends Vue {
  menuOne = false;
  menuTwo = false;

  @Prop({ type: Object })
  model;

  get startDate(): string | null {
    return this.model.startDate ? this.formatDate(new Date(this.model.startDate).toISOString().substr(0, 10)) : null;
  }
  set startDate(value: string | null) {
    this.model.startDate = this.parseDate(value);
  }

  get endDate(): string | null {
    return this.model.endDate ? this.formatDate(new Date(this.model.endDate).toISOString().substr(0, 10)) : null;
  }
  set endDate(value: string | null) {
    this.model.endDate = this.parseDate(value);
  }

  @Watch('menuOne')
  private async mnuOne(val) {
    val && setTimeout(() => ((this.$refs['pickerOne'] as any).activePicker = 'YEAR'));
  }

  @Watch('menuTwo')
  private async mnuTwo(val) {
    val && setTimeout(() => ((this.$refs['pickerTwo'] as any).activePicker = 'YEAR'));
  }

  formatDate(date): any {
    if (!date) return null;

    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }

  parseDate(date): any {
    if (!date) return null;

    const [month, day, year] = date.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
}
