


































































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import Api from '../../utils/api';
import Select from '@/src/types/generic/select';

import Person from '../../types/people/person';

import CommandsComponent from '../../auth/views/Commands.vue';

@Component({
  components: {
    CommandsComponent: CommandsComponent
  }
})
export default class ClientForm extends Vue {
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  valid = null;
  loading = false;
  saving = false;
  isCreate = true;
  rules = [(v: any) => !!v || 'This field is required'];

  clients: Array<any> = [];

  personTypes: Array<Select> = [
    {
      text: 'Physical',
      value: 0
    },
    {
      text: 'Legal',
      value: 1
    }
  ];

  externalTypes: Array<Select> = [
    {
      text: 'Entity',
      value: 0
    },
    {
      text: 'Individual',
      value: 1
    }
  ];

  person = {} as Person;

  async created() {
    this.isCreate = !this.$route.params.id;
    //await this.getIndividuosAsync();

    if (!this.isCreate) {
      await this.getPersonAsync();
    }
    await this.getClientsAsync();
  }

  async getClientsAsync(): Promise<void> {
    const resp = await this.$http.get(
      `${Api.Url.person.getClients}?${this.$to_qs({
        skip: 0,
        take: 1000
      })}`
    );

    if (resp.data.statusCode.item1 === 200) {
      console.log(resp.data.data.data);

      this.clients = resp.data.data.data.map(x => {
        return { text: x.name, value: x.id };
      });
    }
  }

  async getPersonAsync(): Promise<void> {
    this.loading = true;
    const resp = await this.$http.get(`${Api.Url.person.getPerson}/${this.$route.params.id}`);

    if (resp.data.statusCode.item1 === 200) {
      this.person = resp.data.data;
    } else {
      this.setSnackbar({
        show: true,
        message: resp.data.statusCode.item2,
        color: 'error'
      });
    }

    this.loading = false;
  }

  async saveAsync(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.saving = true;
      if (this.isCreate) {
        await this.createAsync();
      } else {
        await this.updateAsync();
      }

      this.$router.push({ name: 'IndexPerson' });
    }
  }

  async createAsync(): Promise<void> {
    try {
      const resp = await this.$http.post(Api.Url.person.create, {
        person: this.person
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message = resp.data.statusCode.item1 === 200 ? 'Person saved with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in create Person',
        color: 'error'
      });
    }
  }

  async updateAsync(): Promise<void> {
    try {
      const resp = await this.$http.put(Api.Url.person.update, {
        id: this.$route.params.id,
        person: this.person
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message = resp.data.statusCode.item1 === 200 ? 'Person updated with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in update Person',
        color: 'error'
      });
    }
  }
}
