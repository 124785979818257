















































import { Component, Vue } from 'vue-property-decorator';
import qs from 'qs';
import Api from '../../utils/api';

import Header from '../../types/app/header';
import InflowHeader from '../../types/app/inflowHeader';

import CommandCreate from '../../auth/views/CommandCreate.vue';
import CommandEdit from '../../auth/views/CommandEdit.vue';
import Inflow from '@/src/types/inflow/inflow';

@Component({
  components: {
    CommandCreate: CommandCreate,
    CommandEdit: CommandEdit
  }
})
export default class IndexRescue extends Vue {
  headers: Array<Header> = InflowHeader.header;
  loading = false;
  inflows: Array<any> = [];
  debounceSearch: any = null;

  filter = {
    skip: 0,
    take: 10,
    search: '',
    personIds: [],
    fundIds: [],
    officerIds: []
  };

  async created() {
    await this.getInflows();
  }

  async getInflows(): Promise<void> {
    try {
      const resp = await this.$http.get(`${Api.Url.inflow.getInflows}?${qs.stringify(this.filter)}`);

      console.log(JSON.parse(JSON.stringify(resp)));

      if (resp.status === 200 && resp.data.data) {
        resp.data.data.data.forEach((inflow: Inflow) => {
          console.log(JSON.parse(JSON.stringify(inflow)));
          this.inflows.push({
            id: inflow.id,
            fund: inflow.fund?.name,
            client: inflow.person?.name,
            account: inflow.account,
            financial: this.$formatCurrency(inflow.financial, true)
          });
        });
      }
    } catch (error) {
      console.log('error_inflow', error);
    }
  }

  goToDetail(item: any): void {
    this.$router.push({ name: 'FormInflow', params: { id: item.id } });
  }
}
