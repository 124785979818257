import Header from './header';

export default class OfficerHeader {
  public static header: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Short Name',
      align: 'start',
      sortable: true,
      value: 'shortName',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Active',
      align: 'start',
      sortable: true,
      value: 'active',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Edit',
      align: 'right',
      value: 'actions',
      sortable: false,
      class: null,
      width: '5%'
    }
  ];
}
