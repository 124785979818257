import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Auth } from './auth/plugins/auth';
import Http from './plugins/http';
import vuetify from './plugins/vuetify';
import { Utils } from './plugins/utils';

Vue.config.productionTip = false;

Vue.use(Auth);
Vue.use(new Http());
Vue.use(Utils);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
