import Vue from 'vue';
import Vuex from 'vuex';

import app from '../vuex/app';
import auth from '../auth/vuex/perm';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    app
  }
});
