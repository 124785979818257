import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Login from '../auth/views/Login.vue';
import Logout from '../auth/views/Logout.vue';

import IndexPerson from '../views/Person/Index.vue';
import FormPerson from '../views/Person/Form.vue';

import IndexInflow from '../views/Inflow/Index.vue';
import FormInflow from '../views/Inflow/Form.vue';

import IndexOutflow from '../views/Outflow/Index.vue';
import FormOutflow from '../views/Outflow/Form.vue';

import IndexFund from '../views/Fund/Index.vue';
import FormFund from '../views/Fund/Form.vue';

import IndexOfficer from '../views/Officer/Index.vue';
import FormOfficer from '../views/Officer/Form.vue';

import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login', //popup_window_error
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/people',
    name: 'IndexPerson',
    meta: {
      parent: 'Individuals',
      create: 'Person',
      edit: 'Person',
      title: 'People'
    },
    component: IndexPerson
  },
  {
    path: '/person/:id?',
    name: 'FormPerson',
    meta: {
      parent: 'Individuals',
      before: 'IndexPerson',
      index: 'people',
      title: 'Person'
    },
    component: FormPerson
  },
  {
    path: '/inflows',
    name: 'IndexInflow',
    meta: {
      parent: 'Management',
      create: 'FormInflow',
      edit: 'FormInflow',
      title: 'Inflows'
    },
    component: IndexInflow
  },
  {
    path: '/inflow/:id?',
    name: 'FormInflow',
    meta: {
      parent: 'Management',
      before: 'IndexInflow',
      index: 'inflows',
      title: 'Inflow'
    },
    component: FormInflow
  },
  {
    path: '/outflows',
    name: 'IndexOutflow',
    meta: {
      parent: 'Management',
      create: 'FormOutflow',
      edit: 'FormOutflow',
      title: 'Outflows'
    },
    component: IndexOutflow
  },
  {
    path: '/outflow/:id?',
    name: 'FormOutflow',
    meta: {
      parent: 'Management',
      before: 'IndexOutflow',
      index: 'outflows',
      title: 'Outflow'
    },
    component: FormOutflow
  },
  {
    path: '/funds',
    name: 'IndexFund',
    meta: {
      parent: 'Leste Entity',
      create: 'FormFund',
      edit: 'FormFund',
      title: 'Funds'
    },
    component: IndexFund
  },
  {
    path: '/fund/:id?',
    name: 'FormFund',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexFund',
      index: 'funds',
      title: 'Fund'
    },
    component: FormFund
  },
  {
    path: '/officers',
    name: 'IndexOfficer',
    meta: {
      parent: 'Management',
      create: 'Officer',
      edit: 'Officer',
      title: 'Officers'
    },
    component: IndexOfficer
  },
  {
    path: '/officer/:id?',
    name: 'FormOfficer',
    meta: {
      parent: 'Management',
      before: 'IndexOfficer',
      index: 'officers',
      title: 'Officer'
    },
    component: FormOfficer
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const token = await Vue.prototype.$getTokenAsync();

  if (to.name == 'Login' || to.name == 'Logout' || (token && token !== 'undefined' && token !== undefined)) {
    next();
  } else {
    next({ name: 'Login' });
  }
});

export default router;
