


















































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import axios from 'axios';
import Endpoint from '../utils/endpoint';

import User from '../types/user';
import Area from '../types/area';

@Component({})
export default class Navigation extends Vue {
  instance = axios.create();

  @Getter('getDrawer', { namespace: 'app' }) getDrawer!: boolean;
  @Getter('getAreas', { namespace: 'auth' }) areas!: Area[];

  @Action('setDrawer', { namespace: 'app' }) setDrawer: any;
  @Action('setAreas', { namespace: 'auth' }) setAreas: any;

  user: User | null = null;
  value = 0;
  gettingMenu = false;
  interval = {} as number;

  get drawer(): boolean {
    return this.getDrawer;
  }
  set drawer(drawer: boolean) {
    this.setDrawer(drawer);
  }

  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 500);
  }

  async created() {
    this.user = await this.$getUserAsync();
    await this.getMenusAsync();
    this.setActive();
  }

  async getMenusAsync(): Promise<void> {
    try {
      this.gettingMenu = true;

      const token = await this.$getTokenForMenuAsync();

      const resp = await this.instance({
        method: 'get',
        url: `${Endpoint.Url.auth.getMenus}/876254c5-4df6-4163-841c-6128a189d327`,
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!resp.data.hasStatusErrors) {
        this.setAreas(resp.data.data);
      }

      this.gettingMenu = false;
      clearInterval(this.interval);
    } catch (error) {
      console.log('error menus', error.data);
    }
  }

  active(route): boolean {
    return this.$route.name === route || this.$route.meta.before === route;
  }

  setActive(): void {
    for (let i = 0; i < this.areas.length; i++) {
      this.areas[i].active = this.areas[i].title === this.$route.meta.parent;
    }
  }

  goTo(route: string): void {
    this.$router.push({ name: route });
  }
}
