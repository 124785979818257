import { UserState } from './types';
import Auth from '../../utils/auth';
import Area from '../../types/area';

export default {
  getAreas(state: UserState): Area[] {
    return state.areas !== null ? state.areas : [];
  },
  canCreate(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(state.areas, state.currentRoute.parent, state.currentRoute.name, 'Create');
    }

    return false;
  },
  canUpdate(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(state.areas, state.currentRoute.parent, state.currentRoute.name, 'Update');
    }

    return false;
  },
  canRead(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(state.areas, state.currentRoute.parent, state.currentRoute.name, 'Update');
    }

    return false;
  },
  canDelete(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(state.areas, state.currentRoute.parent, state.currentRoute.name, 'Delete');
    }

    return false;
  }
};
