const host = process.env.VUE_APP_URL_CORPORATIVE_API;

export default class Api {
  public static readonly Url = {
    officer: {
      getOfficer: `${host}/api/investor/officer`,
      getOfficers: `${host}/api/investor/officer`,
      createOfficer: `${host}/api/investor/officer`,
      updateOfficer: `${host}/api/investor/officer`
    },
    person: {
      getPeople: `${host}/api/investor/person`,
      create: `${host}/api/investor/person`,
      getPerson: `${host}/api/investor/person`,
      update: `${host}/api/investor/person`,
      getClients: `${host}/api/client/getbyfilter`
    },
    fund: {
      getVehicles: `${host}/api/entity/filter`,
      create: `${host}/api/investor/fund`,
      getFund: `${host}/api/investor/fund`,
      update: `${host}/api/investor/fund`,
      getFunds: `${host}/api/investor/fund`
    },
    inflow: {
      getInflows: `${host}/api/investor/inflow/filter`,
      get: `${host}/api/investor/inflow`,
      create: `${host}/api/investor/inflow`,
      update: `${host}/api/investor/inflow`
    },
    outflow: {
      getOutflows: `${host}/api/investor/outflow/filter`,
      get: `${host}/api/investor/outflow`,
      create: `${host}/api/investor/outflow`,
      update: `${host}/api/investor/outflow`
    },
    individual: {
      getByTable: `${host}/api/individual/getbytable`
    }
  };
}
