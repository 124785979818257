
































































































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import Dates from '../../components/generic/Dates.vue';

import Api from '../../utils/api';
import Select from '../../types/generic/select';
import Officer from '../../types/officer/officer';
import Individuo from '../../types/officer/individuo';

import CommandsComponent from '../../auth/views/Commands.vue';

@Component({
  components: {
    Dates: Dates,
    CommandsComponent: CommandsComponent
  }
})
export default class RescueForm extends Vue {
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  valid = null;
  loading = false;
  saving = false;
  isCreate = true;
  officer = {
    individuos: [] as Individuo[]
  } as Officer;
  individualIds: Array<string> = [];
  individuos: Array<Select> = [];

  rules = [(v: any) => !!v || 'This field is required'];

  async created() {
    this.isCreate = !this.$route.params.id;
    await this.getIndividuosAsync();

    if (!this.isCreate) {
      await this.getOfficerAsync();
    }
  }

  async getOfficerAsync(): Promise<void> {
    this.loading = true;
    const resp = await this.$http.get(`${Api.Url.officer.getOfficer}/${this.$route.params.id}`);

    if (resp.data.statusCode.item1 === 200) {
      this.officer = resp.data.data;
    } else {
      this.setSnackbar({
        show: true,
        message: resp.data.statusCode.item2,
        color: 'error'
      });
    }

    this.loading = false;
  }

  async getIndividuosAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(`${Api.Url.individual.getByTable}/individuals/id/txname`);

      if (resp.status === 200) {
        this.individuos = resp.data;
      }
    } catch (error) {
      console.log('individuos', error);
    }
  }

  addIndividuo(): void {
    if (this.officer.individuos === null) this.officer.individuos = [];

    this.officer.individuos?.push({
      individuoId: null,
      startDate: null,
      endDate: null
    });
  }

  removeRelation(index: number): void {
    this.officer.individuos?.splice(index, 1);
  }

  async saveAsync(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.saving = true;
      if (this.isCreate) {
        await this.createAsync();
      } else {
        await this.updateAsync();
      }

      this.$router.push({ name: 'IndexOfficer' });
    }
  }

  async createAsync(): Promise<void> {
    try {
      this.officer.active = true;
      const resp = await this.$http.post(Api.Url.officer.createOfficer, {
        officer: this.officer
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message = resp.data.statusCode.item1 === 200 ? 'Officer saved with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in create officer',
        color: 'error'
      });
    }
  }

  async updateAsync(): Promise<void> {
    try {
      const resp = await this.$http.put(Api.Url.officer.updateOfficer, {
        id: this.$route.params.id,
        officer: this.officer
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message =
        resp.data.statusCode.item1 === 200 ? 'Officer updated with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in update officer',
        color: 'error'
      });
    }
  }

  goToIndex(): string {
    console.log('this.$route.meta.index', this.$route.meta.index);

    return this.$route.meta.index;
  }
}
