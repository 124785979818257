import { AppState } from './types';

import Snackbar from '../../types/app/snackbar';

export default {
  getSnackbar(state: AppState): Snackbar {
    return state.snackbar;
  },
  getOverlay(state: AppState): boolean {
    return state.overlay;
  },
  getDrawer(state: AppState): boolean {
    return state.drawer;
  }
};
