





















import { Component, Watch, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Navigation from '../src/auth/views/Navigation.vue';
import Bar from '../src/auth/views/Bar.vue';
import Snackbar from './types/app/snackbar';

@Component({
  components: {
    Navigation: Navigation,
    Bar: Bar
  }
})
export default class App extends Vue {
  @Getter('getSnackbar', { namespace: 'app' }) snackbar!: Snackbar;
  @Getter('getOverlay', { namespace: 'app' }) overlay!: boolean;

  @Action('setCurrentRoute', { namespace: 'auth' }) setCurrentRoute: any;
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  @Watch('$route')
  private route(route) {
    this.setCurrentRoute({
      parent: this.$route.meta.parent,
      name: this.$route.name
    });
  }
  drawer = true;

  closeSnackbar(): void {
    this.setSnackbar({
      show: false,
      color: '',
      message: ''
    });
  }
}
