




























































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import Api from '../../utils/api';
import CommandsComponent from '../../auth/views/Commands.vue';

import Select from '@/src/types/generic/select';
import Fund from '../../types/fund/fund';

@Component({
  components: {
    CommandsComponent: CommandsComponent
  }
})
export default class VehicleForm extends Vue {
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  fund = {
    administrationTax: 0
  } as Fund;

  valid = null;
  loading = false;
  saving = false;
  isCreate = true;
  funds: Array<Select> = [];

  isValueActive = false;
  rules = [(v: any) => !!v || 'This field is required'];

  async created() {
    this.isCreate = !this.$route.params.id;

    if (!this.isCreate) {
      await this.getFundAsync();
    }

    await this.getFundsAsync();
  }

  async getFundsAsync(): Promise<void> {
    const resp = await this.$http.get(`${Api.Url.fund.getVehicles}?
      ${this.$to_qs({
        skip: 0,
        take: 1000,
        entityType: 'vehicle'
      })}`);

    if (resp.status === 200 && resp.data.data) {
      this.funds = resp.data.data.map(x => {
        return {
          text: x.shortName,
          value: x.id
        };
      });
    }
  }

  async getFundAsync(): Promise<void> {
    const resp = await this.$http.get(`${Api.Url.fund.getFund}/${this.$route.params.id}`);

    if (resp.status === 200 && !resp.data.hasStatusErrors) {
      this.fund = resp.data.data;
    }
  }

  get value(): string | null {
    if (this.isValueActive || !this.fund.administrationTax) {
      return `${this.fund.administrationTax}`;
    } else {
      return this.$formatCurrency(this.fund.administrationTax, true);
    }
  }
  set value(value: string | null) {
    if (!value) value = '0';

    let newValue = parseFloat(value.replace(/[^\d\\.]/g, ''));
    if (isNaN(newValue)) {
      newValue = 0;
    }
    this.fund.administrationTax = newValue;
  }

  get valueFormated(): string {
    return this.$formatCurrency(this.fund.administrationTax, this.isValueActive);
  }

  async saveAsync(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.saving = true;
      if (this.isCreate) {
        await this.createAsync();
      } else {
        await this.updateAsync();
      }

      this.$router.push({ name: 'IndexFund' });
    }
  }

  async createAsync(): Promise<void> {
    try {
      const resp = await this.$http.post(Api.Url.fund.create, {
        fund: this.fund
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message = resp.data.statusCode.item1 === 200 ? 'Fund saved with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in create Fund',
        color: 'error'
      });
    }
  }

  async updateAsync(): Promise<void> {
    try {
      const resp = await this.$http.put(Api.Url.fund.update, {
        id: this.$route.params.id,
        fund: this.fund
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message = resp.data.statusCode.item1 === 200 ? 'Fund updated with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in update Fund',
        color: 'error'
      });
    }
  }
}
