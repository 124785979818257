import Vue, { PluginObject, VueConstructor } from 'vue';
import axios, { AxiosInstance } from 'axios';

export default class Http implements PluginObject<void> {
  public readonly instance: AxiosInstance;

  public constructor() {
    this.instance = axios.create();

    this.instance.interceptors.request.use(async resp => {
      const token = await Vue.prototype.$getTokenAsync();

      if (token !== null) {
        resp.headers['Authorization'] = `Bearer ${token}`;
      }

      return resp;
    });

    this.instance.interceptors.response.use(
      async response => {
        return response;
      },
      async error => {
        console.log('resp', JSON.parse(JSON.stringify(error)));
      }
    );
  }

  public install(Vue: VueConstructor) {
    Vue.prototype.$http = this.instance;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance;
  }
}
