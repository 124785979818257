import { ActionContext } from 'vuex';
import { AppState } from './types';

import Snackbar from '../../types/app/snackbar';

export default {
  setSnackbar(store: ActionContext<AppState, any>, snackbar: Snackbar): void {
    store.commit('setSnackbar', snackbar);
  },
  setOverlay(store: ActionContext<AppState, any>, overlay: boolean): void {
    store.commit('setOverlay', overlay);
  },
  setDrawer(store: ActionContext<AppState, any>, drawer: boolean): void {
    store.commit('setDrawer', drawer);
  }
};
