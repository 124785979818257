




















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Login extends Vue {
  @Prop({ type: Boolean })
  dialog;

  @Prop({ type: Boolean })
  loader;

  @Prop({ type: Boolean })
  isLogged;

  async created() {
    await this.initializeAsync();
  }

  async initializeAsync(): Promise<void> {
    const result: boolean = await this.$loginAsync();
    if (result) {
      window.location.href = '/';
    } else {
      await this.tryLoginAsync();
    }
  }

  async tryLoginAsync(): Promise<any> {
    console.log('try login');
  }

  async getLesteUserAsync(email: string) {
    console.log('leste user');
  }

  async cancelLogin(): Promise<void> {
    this.dialog = false;
    sessionStorage.clear();
    localStorage.clear();
  }
}
