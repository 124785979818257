



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import axios from 'axios';

import User from '../types/user';

@Component({})
export default class Bar extends Vue {
  instance = axios.create();

  @Getter('getDrawer', { namespace: 'app' }) getDrawer!: boolean;

  @Action('setDrawer', { namespace: 'app' }) setDrawer: any;

  @Prop({ type: String })
  title;

  user: User | null = null;

  drawer = false;
  menu = false;
  imgUrl = '';

  async created() {
    this.user = await this.$getUserAsync();
    await this.getGraphToken();
  }

  showMenu(): void {
    this.setDrawer(true);
  }

  async getGraphToken(): Promise<void> {
    const graphToken = await this.$getTokenGraphAsync();
    if (graphToken != null) {
      try {
        const resp = await this.instance({
          method: 'get',
          url: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
          responseType: 'blob',
          headers: { Authorization: `Bearer ${graphToken}` }
        });

        this.imgUrl = URL.createObjectURL(resp.data);
      } catch (error) {
        this.imgUrl = require('../../assets/avatar.png');
      }
    } else {
      this.imgUrl = require('../../assets/avatar.png');
    }
  }

  cleanCache(): void {
    this.$router.push({ name: 'Home' });
  }

  logout(): void {
    this.$logoutAsync();
  }
}
