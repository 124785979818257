export default {
  overlay: false,
  drawer: false,
  snackbar: {
    show: false,
    color: '',
    message: '',
    multiline: false
  }
};
