import { AppState } from './types';

import Snackbar from '../../types/app/Snackbar';

export default {
  setSnackbar(state: AppState, snackbar: Snackbar) {
    state.snackbar = snackbar;
  },
  setOverlay(state: AppState, overlay: boolean): void {
    state.overlay = overlay;
  },
  setDrawer(state: AppState, drawer: boolean): void {
    state.drawer = drawer;
  }
};
