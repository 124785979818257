







































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import Api from '../../utils/api';
import CommandsComponent from '../../auth/views/Commands.vue';

import Outflow from '../../types/outflow/outflow';
import Decimal from '../../components/generic/Decimal.vue';
import Integer from '../../components/generic/Integer.vue';
import Date from '../../components/generic/Date.vue';

@Component({
  components: {
    Decimal: Decimal,
    Integer: Integer,
    Date: Date,
    CommandsComponent: CommandsComponent
  }
})
export default class RescueForm extends Vue {
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  isCreate = true;
  valid = null;
  saving = false;
  loading = false;
  loadingFunds = true;
  loadingPeople = true;
  funds: Array<any> = [];
  people: Array<any> = [];
  rescueTypes: Array<any> = [
    {
      text: 'Total',
      value: 0
    },
    {
      text: 'Partial',
      value: 1
    }
  ];
  financialQuantities: Array<any> = [
    {
      text: 'Q',
      value: 'Q'
    },
    {
      text: 'V',
      value: 'V'
    }
  ];
  rules = [(v: any) => !!v || 'This field is required'];

  quotaQuantity = {
    value: 0
  };

  rescueNumber = {
    value: 0
  };

  quotaValue = {
    value: 0
  };

  quotaValueDate = {
    date: ''
  };

  irValue = {
    value: 0
  };

  iofValue = {
    value: 0
  };

  awardValue = {
    value: 0
  };

  aquisitionNumber = {
    value: 0
  };

  financial = {
    value: 0
  };

  financialDate = {
    date: ''
  };

  outflow = {
    financialQuantity: 0
  } as Outflow;

  async created() {
    this.isCreate = !this.$route.params.id;
    await this.getFundsAsync();
    await this.getPeopleAsync();

    if (!this.isCreate) {
      await this.getOutflowAsync();
    }
  }

  async getFundsAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(
        `${Api.Url.fund.getFunds}?${this.$to_qs({
          skip: 0,
          take: 1000
        })}`
      );

      if (resp.data) {
        this.funds = resp.data.data.data.map(x => {
          return {
            text: x.name,
            value: x.id
          };
        });
      }
      this.loadingFunds = false;
    } catch (error) {
      this.loadingFunds = false;
      console.log('error', error);
    }
  }

  async getPeopleAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(
        `${Api.Url.person.getPeople}?${this.$to_qs({
          skip: 0,
          take: 1000
        })}`
      );
      if (resp.data) {
        this.people = resp.data.data.data.map(x => {
          return {
            text: x.name,
            value: x.id
          };
        });
      }
    } catch (error) {
      this.loadingPeople = false;
      console.log('error', error);
    }
  }

  async getOutflowAsync(): Promise<void> {
    console.log('buscando');

    try {
      const resp = await this.$http.get(`${Api.Url.outflow.get}/${this.$route.params.id}`);

      console.log('resp.data.data', resp.data);
      if (resp.status === 200 && resp.data) {
        this.outflow = resp.data.data;

        this.quotaQuantity.value = this.outflow.quotaQuantity ?? 0;
        this.quotaValueDate.date = this.outflow.quotaValueDate ?? '';
        this.rescueNumber.value = this.outflow.rescueNumber ?? 0;
        this.quotaValue.value = this.outflow.quotaValue ?? 0;
        this.irValue.value = this.outflow.irValue ?? 0;
        this.iofValue.value = this.outflow.iofValue ?? 0;
        this.awardValue.value = this.outflow.awardValue ?? 0;
        this.aquisitionNumber.value = this.outflow.aquisitionNumber ?? 0;
        this.financial.value = this.outflow.financial ?? 0;
        this.financialDate.date = this.outflow.financialDate ?? '';
      }
    } catch (error) {
      console.log('individuos', error);
    }
  }

  async saveAsync(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.outflow.quotaQuantity = this.quotaQuantity.value;
      this.outflow.rescueNumber = this.rescueNumber.value;
      this.outflow.quotaValue = this.quotaValue.value;
      this.outflow.irValue = this.irValue.value;
      this.outflow.iofValue = this.iofValue.value;
      this.outflow.awardValue = this.awardValue.value;
      this.outflow.aquisitionNumber = this.aquisitionNumber.value;
      this.outflow.financial = this.financial.value;
      this.outflow.financialDate = this.financialDate.date;

      console.log(JSON.parse(JSON.stringify(this.outflow)));

      this.saving = true;
      if (this.isCreate) {
        await this.createAsync();
      } else {
        await this.updateAsync();
      }
    }
  }

  async createAsync(): Promise<void> {
    try {
      const resp = await this.$http.post(Api.Url.outflow.create, {
        outflow: this.outflow
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message = resp.data.statusCode.item1 === 200 ? 'Outflow saved with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
      this.$router.push({ name: 'IndexOutflow' });
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in create Outflow',
        color: 'error'
      });
    }
  }

  async updateAsync(): Promise<void> {
    try {
      const resp = await this.$http.put(Api.Url.outflow.update, {
        id: this.$route.params.id,
        outflow: this.outflow
      });

      const color = resp.data.statusCode.item1 === 200 ? 'success' : 'error';
      const message =
        resp.data.statusCode.item1 === 200 ? 'Outflow updated with success' : resp.data.statusCode.item2;

      this.setSnackbar({
        show: true,
        message: message,
        color: color
      });

      this.saving = false;
      this.$router.push({ name: 'IndexOutflow' });
    } catch (error) {
      this.saving = false;
      console.log('error', error);

      this.setSnackbar({
        show: true,
        message: 'Error in update Outflow',
        color: 'error'
      });
    }
  }
}
