














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DecimalComponent extends Vue {
  @Prop({ type: Object })
  model;

  @Prop({ type: Array })
  rules;

  @Prop({ type: String })
  label;

  isValueActive = false;

  get value(): string | null {
    if (this.isValueActive || !this.model.value) {
      return `${this.model.value}`;
    } else {
      return this.$formatNumber(this.model.value, true);
    }
  }
  set value(value: string | null) {
    if (!value) value = '0';

    let newValue = parseFloat(value.replace(/[^\d\\.]/g, ''));
    if (isNaN(newValue)) {
      newValue = 0;
    }
    this.model.value = newValue;
  }

  get valueFormated(): string {
    return this.$formatNumber(this.model.value, this.isValueActive);
  }
}
